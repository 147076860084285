.AuthData {
    margin: auto;
    padding: 40px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    background-color: white;
    position: relative;
    top: 50px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.createAccount {
    margin-top: 40px;
}

.contentSpacing {
    margin: 20px 0px;
}

@media (min-width: 600px) {
    .AuthData {
        margin: auto;
        width: 450px;
        text-align: center;
    }
}