.overlay-control {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
}