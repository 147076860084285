.registrationContainer {
    margin: auto;
    padding: 40px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    background-color: white;
    position: relative;
    top: 50px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    min-height: 500px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.registrationForm {
    min-height: 410px;
    margin-top: 10px;
}
.registrationTabs{
    flex-shrink: 1;
}

.a {
    text-decoration: none;
}

.a:hover input, .a:hover svg {
    color: #EE6E2C;
    cursor: pointer
}

@media (min-width: 600px) {
    .registrationContainer {
        margin: auto;
        width: 700px;
        text-align: center;
    }
}

.gridContainer {
    margin-top: 20px;
}

.active {
    color: #EE6E2C !important;
    border-bottom: 1px solid #EE6E2C !important;
}

.onboardingTabs {
    min-width: 90px !important;
    max-width: 17% !important;
    flex-basis: 17% !important;
}

@media (max-width: 768px) {
    .onboardingTabs {
        min-width: 33% !important;
        max-width: 33% !important;
        flex-basis: 33% !important;
        padding-top: 20px;
    }
}

.gridItem {
    border-bottom: 2px solid black;
}

.registrationloadingIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px !important;
    height: 200px !important;
    margin: auto;
    text-align: center;
}

.registrationCompleteIcon {
    width: 200px !important;
    height: 200px !important;
    position: relative;
    margin: auto;
    text-align: center;
    display: flex !important;
    color: #EE6E2C;
}

.hideCompanyName {
  display:none !important;
}
.showCompanyName {
    display:block !important;
}
