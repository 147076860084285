* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #f5f5f5;
    overflow: hidden;
}

.kiepwagen {
    position: absolute;
    bottom: -20px;
    right: -300px; /* Start the truck off-screen */
    width: 500px;
    height: 220px;
    animation: drive 6s linear infinite;
    z-index: 9999;
}

.truck-body {
    position: absolute;
    bottom: 30px;
    width: 105%;
    height: 120px;
    right:10px;
    background-color: #8a8a8a;
    border-radius: 15px 15px 0 0;
}

.dump-bed {
    position: absolute;
    width: 80%;
    height: 100px;
    right:0;
    background-color: #ffcc00; /* Yellow for the dump bed */
    border-radius: 5px;
    /*transform: rotate(15deg); !* Tilting the bed *!*/
    bottom: 100px; /* Placing it higher up to tilt */
}

.cab {
    position: absolute;
    width: 140px;
    height: 120px;
    /*background-color: #333; !* Darker color for the cab *!*/
    background-color: #8a8a8a; /* Darker color for the cab */
    border-radius: 5px;
    top: -20px;
    left: 0;
}
.cab-window {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: #333; /* Darker color for the cab */
    border-radius: 5px;
    top: 10px;
    left: 20px;
}

.wheel {
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: #333; /* Dark wheel color */
    border-radius: 50%;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spin 1s linear infinite; /* Spinning animation for the wheels */
}

.left-wheel {
    left: 30px;
}

.right-wheel {
    right: 30px;
}

.left-wheel-rear {
    left: 100px;
}

.right-wheel-rear {
    right: 100px;
}

.rim {
    width: 70px;
    height: 70px;
    background-color: #666; /* Rim color */
    border-radius: 50%;
}

.tread {
    width: 50px;
    height: 50px;
    background-color: #222; /* Tread color */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Chunky outer tread blocks */
.outer-tread {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow:
            0 0 0 10px rgba(0, 0, 0, 0.2), /* Outer block shadow */
            inset 0 0 0 5px #444; /* Inner block shadow for tread */
    background: transparent;
    z-index: 1;
}

/* Create blocks along the outer circumference */
.outer-tread:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    background-color: #444;
    border-radius: 50%;
}

.outer-tread:nth-child(3):before {
    content: '';
    position: absolute;
    top: 50px;
    left: 20px;
    width: 20px;
    height: 20px;
    background-color: #444;
    border-radius: 50%;
}

/* Exhaust pipe */
.exhaust-pipe {
    position: absolute;
    bottom: 30px;
    right: 0;
    width: 20px;
    height: 40px;
    background-color: #333;
    border-radius: 5px;
    z-index: 100;
}

/* Smoke animation */
.smoke {
    position: absolute;
    bottom: 80px;
    right: 15px;
    z-index: 99;
}

.smoke-puff {
    width: 30px;
    height: 30px;
    background-color: rgba(169, 169, 169, 0.8); /* Light gray smoke color */
    border-radius: 50%;
    animation: puff 2s ease-out infinite;
    position: absolute;
    opacity: 0;
}

.smoke-puff:nth-child(1) {
    animation-delay: 0s;
    left: 0;
}

.smoke-puff:nth-child(2) {
    animation-delay: 0.5s;
    left: 40px;
}

.smoke-puff:nth-child(3) {
    animation-delay: 1s;
    left: 80px;
}

@keyframes puff {
    0% {
        transform: translateY(0) scale(0.5);
        opacity: 1;
    }
    100% {
        transform: translateY(-50px) scale(1.5);
        opacity: 0;
    }
}

@keyframes drive {
    0% {
        right: -300px; /* Start position off the screen on the right */
    }
    100% {
        right: 100%; /* End position off the screen on the left */
    }
}

@keyframes spin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}