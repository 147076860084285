.checkContainer {
    margin: auto;
    padding: 40px 0 40px 40px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    position: relative;
    top: 50px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.checkContainerLoggedIn {
    margin: auto;
    padding: 0 0 40px 40px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    position: relative;
    top: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.checkDivider{
    margin: 20px 0 !important;
}

.checkPaperInfo{
    padding:20px;
}
.checkPaper{
    max-width: 1150px;
    display: block;
}

.containerInfo{
    width: 27%;
    top: 0;
    bottom: 0;
    margin-top: 60px;
    padding: 31px 20px;
    left: 0;
    height: 100vh;
    position: fixed;
}

.buttonContainer{
    margin-top:10px !important;
}
.button{
    margin-right:20px !important;
}
.mobileScrollInfo{
    display: none;
    align-items: center;
    flex-wrap: wrap;
    margin-top:20px;
    justify-content: center;
    text-align: center;
}

@media (min-width: 1000px) {
    .checkContainer {
        margin: auto;
        width: 100%;
    }
    .checkContainerLoggedIn {
        margin: auto;
        width: 100%;
    }
}
/*@media (max-width: 1200px) {*/
/*    .checkPaper{*/
/*        max-width: 900px;*/
/*    }*/
/*}*/
@media (max-width: 1000px) {
    .containerInfo{
        width:inherit;
        position: relative;
        height:inherit;
        margin-top:30px;
    }
    .mobileScrollInfo{
        display: flex;
    }
    .checkPaper{
        max-width: 800px;
    }
}