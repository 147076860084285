.App {
    height: 100%;
}

.Login {
    background-image: url(assets/images/introBackground.jpg);
    height: 100%;
    background-position: revert;
    background-repeat: no-repeat;
    background-size: cover;
}

.darkOverlay:before {
    background-color: rgba(0, 0, 0, 0.10);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
}
