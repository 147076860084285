@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=block');

body, html, #root {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    font-family: "Roboto", cursive;
}

@font-face {
    font-family: "DaxMedium";
    src: local("DaxMedium"),
    url("./Fonts/dax-medium.woff") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "DaxLight";
    src: local("DaxLight"),
    url("./Fonts/dax-light.otf") format("truetype");
    font-weight: normal;
}
