/*======================================*/
/*Form*/
/*======================================*/

.templateForm {
    color: rgb(113, 43, 1);
    font-size: 14px;
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.42857143;
}

/*======================================*/
/*Containers*/
/*======================================*/
.containerBorderLeft, .containerRequiredBorderLeft {
    border-left: 1px solid rgb(109, 51, 26);
}

.templateForm .disabledContainer {
    background-color: #FAFAFA !important;
    color: #cecece !important;
}

.templateForm .disabledContainer input[type=text] {
    color: #cecece !important;
}

.Mui-disabled {
    border-color: #cecece !important;
}

/*.disabledContainer .MuiInputBase-root {*/
/*    color:#cecece !important;*/
/*    border-color: #cecece !important;*/
/*}*/
/*.disabledContainer .MuiInput-underline.Mui-disabled:after{*/
/*    color:#cecece !important;*/
/*    border-color: #cecece !important;*/
/*}*/

.container, .containerBorderLeft {
    position: relative;
    background-color: #FFFFFF;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.containerRequired, .containerRequiredBorderLeft {
    position: relative;
    background-color: #F0E4DC;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.containerMaybeRequired {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    background-color: rgb(240, 228, 220);
    align-self: normal;
    background-image: repeating-linear-gradient(
            45deg, transparent, transparent 2px, rgba(255, 255, 255, .5) 4px, rgba(255, 255, 255, .5) 8px);

}

.containerRequired > .containerMaybeRequired {
    padding: 0 10px;
}

.containerRequired > div, .container > div, .containerMaybeRequired > div, .emptyContainer > div, .containerBorderLeft > div, .containerRequiredBorderLeft > div {
    padding-left: 15px;
    padding-right: 15px;
}

/*Padding fix for container in container*/
.containerMaybeRequired > .containerRequired > div, .containerRequired > .containerMaybeRequired > div,.containerRequired .containerMaybeRequired, .containerRequired > .container > div, .containerRequired .container {
    padding: 0 !important;
    margin: 0;
}
.subContainer{
    margin: -5px 15px 0px 15px !important
}


/*Block 6 specific css*/
.whiteFiller {
    height: 20px;
    background: #fff;
}

.labelHeader {
    min-height: 70px;
    max-height: 70px;
}

.emptyInput,.containerRequired .emptyInput {
    padding: 7px !important;
    min-height: 34px;
}

/*======================================*/
/* Block Indentifier and Table divider
/*======================================*/
.blockIdentifier {
    position: absolute;
    top: -12px;
    left: 0;
    padding-left: 15px;
    width: 30px;
    z-index: 10;
}
.blockPart{
    font-size:10px;
    vertical-align:top;
}

.borderDivider, .borderDividerTop, .borderDividerFull, .borderDivider > div, .borderDividerTop > div {
    position: relative;
}

.borderDivider:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    top: -14px;
    height: 5px;
    width: 94%;
    border-bottom: 1px solid rgb(113, 43, 1);
}

.borderDividerFull:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    top: -14px;
    height: 5px;
    width: 97%;
    border-bottom: 1px solid rgb(113, 43, 1);
}


/*======================================*/
/* Typography
/*======================================*/

/*Title*/
.templateForm h1 {
    color: rgb(109, 51, 26);
    text-align: left;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.1;
}

.templateForm h2, .templateForm span, .templateForm p {
    margin: 0;
}

.templateForm h4 {
    padding-top: 12px;
    margin: 0;
}

/*inputs*/
.templateForm input[type=text], .templateForm input[type=number] {
    margin: 0;
    background-color: transparent;
    width: 100%;
    color: rgb(113, 43, 1);
    height: 30px;
    padding: 2px;
    font-size: 14px;
}


.templateForm .templateGridContainer .inputSmall {
    width: 25%;
}

.templateForm .templateGridContainer .inputMedium {
    width: 50%;
}

.templateForm .templateGridContainer .inputLarge {
    width: 75%;
}

.templateForm .templateGridContainer .inputFull {
    width: 100%;
}

.templateForm button {
    margin: 7px 0 0 0;
    height: 30px;
    padding: 0;
    color: #EE6E2C;
}

.MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
}

/*======================================*/
/* Report number
/*======================================*/
.containerReportNumber{
    border:1px solid rgb(113, 43, 1);
    margin:10px 0;
    background:#FFFFFF;
    font-size: 11px;
    padding:10px 0;
    display: flex;
    align-items: center;
}

/*======================================*/
/* OUDFooter
/*======================================*/
.footerContainer {
    border: 1px solid rgb(109, 51, 26);
    position: relative;
}

.footerContainer > div:nth-child(-n+3) {
    border-bottom: 1px solid rgb(109, 51, 26);
}

.footerContainer > div:nth-child(1), .footerContainer > div:nth-child(4) {
    border-left: none;
}

.footerContainer .footerWhite {
    font-size: 11px;
    min-height: 50px;
    border-left: 1px solid rgb(109, 51, 26);
    padding: 1px 15px;
    position: relative;
}

.footerContainer .footerOrange {
    font-size: 11px;
    min-height: 120px;
    border-left: 1px solid rgb(109, 51, 26);
    background-color: #F0E4DC;
    padding: 1px 15px;
    position: relative;
}

.footerContainer .footerInput {
    position: absolute;
    bottom: 0;
    width: 90%;
    right: 15px;
    left: 15px;
    z-index: 15;

}

.footerContainer .footerInput > div {
    align-self: flex-end;
}

.footerContainer .footerInput input {
    margin-bottom: 5px;
    height: inherit;
}

.footerContainer .footerText {
    position: absolute;
    top: 90px;
    left: 130px;
    font-size: 30px;
    color: darkred;
    z-index: 10;
}

/*======================================*/
/* TemplateOld fixes
/*======================================*/
.containerRequired:nth-child(12) {
    padding-bottom: 0;
}

.containerMaybeRequired:nth-child(2) {
    padding: 0;
    margin-left: -15px;
}

.noWordBreak {
    white-space: nowrap;
    padding: 0;
}

.noWordBreak > div {
    padding: 0 !important;
}
.containerRequired .removeRightPadding{
    padding-right:0px;
}

/*======================================*/
/* RadioButton Styling
/*======================================*/

.templateRadio {
    background-color: transparent;
    border: 1px solid rgb(113, 43, 1);
    border-radius: 10px;
    display: inline-block;
    position: relative;
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    margin: 0;
    margin-right: 5px;
    cursor: pointer;
}

/*Checked*/
.templateRadio:checked {
    background: rgb(113, 43, 1);
}

/*Disabled*/
.templateRadio:disabled {
    cursor: default;
    border: 1px solid rgba(0, 0, 0, 0.54);
}

.templateRadio:checked:disabled {
    background: rgba(0, 0, 0, 0.54);
}

/*Label*/
.radioButtonLabel {
    margin-right: 5px;
}

/*Responsive CSS for tablets*/
@media (max-width: 1024px) {
    .templateForm {
        font-weight: 700;
        font-size: 12px;
    }

    .footerContainer .footerText {
        top: 110px;
        font-size: 20px;
    }
}

/*Responsive CSS for mobile*/
@media only screen and (max-width: 959px) {
    .footerContainer .footerText {
        display: none;
    }

    .footerContainer .footerOrange {
        min-height: 100px;
    }

    .templateForm {
        font-weight: 700;
    }

    .MuiGrid-grid-xs-11 {
        font-weight: 700;
    }

    .templateForm h1 {
        font-size: 30px;
    }

    .containerBorderLeft, .containerRequiredBorderLeft {
        border: none;
    }

    .whiteFiller {
        display: none;
    }

    .labelHeader {
        min-height: 25px;
    }

    .emptyInput {
        padding: 7px;
    }

    .blockIdentifier {
        text-align: center;
        margin-top: 3px;
        margin-left: -20px;
        font-weight: 900;
    }

    .containerMaybeRequired:nth-child(2) {
        margin-left: 0;
    }

    /*Radiobuttons*/
    .radioContainer {
        margin-bottom: 10px !important;
        margin-top: 10px !important;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
    }

    .radioContainer input {
        width: 20px;
        height: 20px;
        margin: 2px;
    }
}

/*Responsive CSS for mobile*/
@media only screen and (max-width: 730px) {
    /*Radiobuttons*/
    .radioContainer {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px !important;
    }

    .radioContainer input,
    .radioContainer label {
        margin: 0;
    }

    .radioContainer input {
        width: 20px;
        height: 20px;
        margin: 2px;
    }

    .radioContainer label {
        padding-left: 10px;
        width: calc(100% - 30px);
    }

}

@media only screen and (max-width: 425px) {
    .templateForm h1 {
        font-size: 25px;
    }

    .templateForm {
        font-weight: 700;
        font-size: 11px;
    }
}